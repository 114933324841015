import React from 'react';
import SEO from '../components/seo';
import EventList from '../components/event-list';

import Layout from '../components/layout';

import './events.scss';

const Events = () => (
  <>
    <SEO title="Events" />
    <Layout scheme="dark" categories="Events">
      <h2 className="events-title">Events</h2>
      <EventList />
    </Layout>
  </>
);

export default Events;
