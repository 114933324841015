import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Event from '../event';
import './event-list.scss';

const EventListPure = ({ data }: any) => (
  <div className="event-list">
    { data.map(({ event }: any) => <Event eventData={event} key={event.slug} />) }
  </div>
);

export default () => (
  <StaticQuery
    query={graphql`
    query WpEvents {
      # Query all WordPress blog posts sorted by date
      allWpEvent(sort: { fields: [date], order: DESC }) {
        edges {
          event: node {
            id
            slug
            title

            eventData {
              startDate
              endDate
            }

            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 675
                      placeholder: BLURRED
                      formats: [AUTO, WEBP]
                      aspectRatio: 1.5
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
    `}
    render={(data) => <EventListPure data={data.allWpEvent.edges} />}
  />
);
