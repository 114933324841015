import React from 'react';
import './event.scss';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import dayjs from 'dayjs';

const Event = (
  {
    eventData: {
      slug, featuredImage, title,
      eventData: {
        startDate, endDate,
      },
    },
  }: any,
) => {
  const image = getImage(featuredImage?.node?.localFile);
  return (
    <div className="event-teaser">
      <Link to={`/events/${slug}`}>
        <div className="event-teaser-image">
          <GatsbyImage image={image} alt={featuredImage?.node?.altText || ''} />
          <div className="event-teaser-date">
            <div className="event-teaser-date-from">
              <div className="event-teaser-date-from-month">
                {dayjs(startDate).format('MMM')}
              </div>
              <div className="event-teaser-date-from-day">
                {dayjs(startDate).format('DD')}
              </div>
            </div>
            { endDate ? (
              <div className="event-teaser-date-to">
                { dayjs(endDate).format('[to] MMM D')}
              </div>
            ) : (
              <div className="event-teaser-date-time">
                { dayjs(startDate).format('HH:mm')}
              </div>
            )}
          </div>
        </div>
        <div className="event-teaser-title">{title}</div>
      </Link>
    </div>
  );
};

export default Event;
